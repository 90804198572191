import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "../styles/Header.scss";
import Modal from "../components/Modal";
import AllBrands from "./AllBrands";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import supabase from "../supabaseclient";

const Header = ({ onSuggestBrandClick }) => {
  const [showModal, setShowModal] = useState(false);
  const [showAllBrands, setShowAllBrands] = useState(false);
  const [totalBrandCount, setTotalBrandCount] = useState(0);
  const allBrandsButtonRef = useRef(null);

  useEffect(() => {
    const fetchTotalBrandCount = async () => {
      const { count, error } = await supabase
        .from('brand-summary')
        .select('*', { count: 'exact' });

      if (error) {
        console.error("Error fetching brand count:", error);
        return;
      }

      setTotalBrandCount(count);
    };

    fetchTotalBrandCount();
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
    onSuggestBrandClick();
  };

  const toggleAllBrands = () => {
    setShowAllBrands(!showAllBrands);
  };

  return (
    <div className="parent-header">
      <div className='header'>
        <a
          className="semibold a-suggest"
          href="#submit-email"
          style={{ textAlign: "center" }}
        >
          <button className="suggest-button" onClick={toggleModal}>
            Suggest a brand
          </button>
        </a>

        <div className="logo-all">
          <button
            ref={allBrandsButtonRef}
            className="all-brands-button"
            onClick={toggleAllBrands}
          >
            All brands ({totalBrandCount})
            <FontAwesomeIcon
              className="icon"
              icon={showAllBrands ? faChevronUp : faChevronDown}
            />
          </button>
        </div>

        {showModal && <Modal onClose={toggleModal} />}
      </div>
      <AllBrands
        isOpen={showAllBrands}
        onToggle={toggleAllBrands}
        buttonRef={allBrandsButtonRef}
      />
    </div>
  );
};

export default Header;